body {
  padding: 0 !important;
  margin: 0 !important;
}

.Loading-header {
    background-color: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

@media screen and (max-width: 998px) {
  #loaderBox {
    width: 25%;
  }
}

@media screen and (max-width: 798px) {
  #loaderBox {
    width: 30%;
  }
}

@media screen and (max-width: 548px) {
  #loaderBox {
    width: 35%;
  }
}